var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.device + "-event",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("投资者关系")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("INVESTORS")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("投資者關係")]) : _vm._e()]), _c("div", {
    staticClass: "companyInfo clearfix"
  }, [_c("ul", {
    staticClass: "tMenuLeft",
    class: _vm.language == 2 ? "tMenuLeftEn" : ""
  }, [_vm.language == 1 || !_vm.language ? _c("div", _vm._l(_vm.irMenuData, function (items, index) {
    return _c("router-link", {
      key: index,
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.name)), _c("em", [_vm._v(">")])])]);
  }), 1) : _vm._e(), _vm.language == 2 ? _c("div", _vm._l(_vm.irMenuData, function (items, index) {
    return _c("router-link", {
      key: index,
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameEn)), _c("em", [_vm._v(">")])])]);
  }), 1) : _vm._e(), _vm.language == 3 ? _c("div", _vm._l(_vm.irMenuData, function (items, index) {
    return _c("router-link", {
      key: index,
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameTr)), _c("em", [_vm._v(">")])])]);
  }), 1) : _vm._e()]), _c("div", {
    staticClass: "comContentRight",
    class: _vm.language == 2 ? "comContentRightEn" : ""
  }, [_c("div", {
    staticClass: "searchBox"
  }, [_c("h4", [_vm._v(_vm._s(_vm.mapText.monthReport[parseInt(_vm.language) - 1]))]), _c("select", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.activity_year,
      expression: "activity_year"
    }],
    on: {
      change: [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.activity_year = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.getData]
    }
  }, _vm._l(_vm.yearArr, function (item) {
    return _c("option", {
      key: item,
      domProps: {
        value: item
      }
    }, [_vm._v(_vm._s(item))]);
  }), 0), _vm.pastActivity.length == 0 ? _c("p", {
    staticClass: "no-result"
  }, [_vm._v(_vm._s(_vm.mapText.noContent[parseInt(_vm.language) - 1]))]) : _vm._e(), _c("div", _vm._l(_vm.pastActivity, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "event-bg",
      class: {
        "deep-bg": index % 2 == 0
      }
    }, [_c("div", {
      staticClass: "layout-left"
    }, [_c("p", {
      staticClass: "activity-title",
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.newspaperPic);
        }
      }
    }, [_vm._v(_vm._s(item.newspaperTitle))])]), false ? _c("div", {
      staticClass: "layout-right"
    }, [_c("p", {
      staticClass: "activity-date",
      staticStyle: {
        "font-size": "1em"
      }
    }, [_c("span", [_vm._v(_vm._s(item.month))]), _c("span", [_vm._v("/")]), _c("span", [_vm._v(_vm._s(item.day))])]), _c("p", {
      staticClass: "activity-date",
      staticStyle: {
        "font-size": "1.2em"
      }
    }, [_vm._v(_vm._s(item.year))]), _c("p", {
      staticClass: "activity-date"
    }, [_vm._v(_vm._s(item.time))])]) : _vm._e()]);
  }), 0)])])])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };